import React, {useState} from "react";
import "./App.css";

const answers = [
    "Davenport",
    "Chicago",
    "Peach",
    "20 minutes",
    "4am",
    "Dad",
    "10 months",
    "The Monk",
    "Mr. Bunny",
    "Fluffy",
    "9lbs 11oz",
    "8lbs 8oz",
    "Sept 26, 2023",
    "Hannah",
    "Jeff Jr. or Caleb",
    "Brown",
    "Dog",
    "Emerald",
    "Amethyst",
    "Rabbit",
    "22 inches",
    "Genesis Hospital",
    "Prentiss Women's Hospital",
    "Dr. Who"
]
const otherAnswers = [
    "Carrot",
    "Wizard of Oz",
    "The Beehive",
    "Taurus",
    "Aquarius",
    "Clammy",
    "Unibrow",
    "7:40am",
    "9:03pm"
]

function generateCard() {
    if (localStorage.getItem("card")) {
        return JSON.parse(localStorage.getItem("card"));
    }
    // const numbers = Array.from({length: 25}, (_, i) => (i + 1) * 2)
    // const oddNumbers = Array.from({length: 25}, (_, i) => (i + 1) * 2 - 1)
    const shuffled = shuffle(answers);
    const shuffledOdds = shuffle(otherAnswers);

    // const conditionOne = [[0, 3], [2,1], [4,3]];
    // const conditionTwo = [[0, 1], [2,3], [4,1]];

    const card = [];
    let xx = false;
    for (let j = 0; j < 5; j++) {
        card.push({number: xx ? "XX" : "XY", clickable: false});
        for (let i = 0; i < 5; i++) {
            let el;
            if (i % 2 === 0 || xx) {
                el = shuffled.pop();
            } else {
                el = shuffledOdds.pop();
            }
            card.push({number: el, clicked: false, clickable: true}); //[(j * 5) + i]
        }
        card.push({number: xx ? "XX" : "XY", clickable: false});
        xx = !xx;
    }
    localStorage.setItem("card", JSON.stringify(card));
    return card;
}

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function BingoCard() {
    const [card, setCard] = useState(generateCard);

    function handleClick(index) {
        const newCard = [...card];
        if (!newCard[index].clickable) return;
        newCard[index].clicked = !newCard[index].clicked;
        setCard(newCard);
        localStorage.setItem("card", JSON.stringify(card));
    }

    const columns = ["", "XX", "XY", "XX", "XY", "XX", ""];

    return (

        <div className="bingo-card">
            {columns.map((column, index) => (
                <div key={index} className={`tile ${column.toLowerCase()}`}>
                    <span>{column}</span>
                </div>
            ))}
            {card.map((tile, index) => (
                <div
                    key={index}
                    className={`tile ${tile.clicked ? "clicked" : ""} ${tile.clickable ? "clickable" : ""} ${Number.isInteger(tile.number) ? "" : tile.number.toLowerCase()}`}
                    onClick={() => handleClick(index)}
                >
                    <span>{tile.number}</span>
                </div>
            ))}
            {columns.map((column, index) => (
                <div key={index} className={`tile ${column.toLowerCase()}`}>
                    <span>{column}</span>
                </div>
            ))}
        </div>
    );
}

export default BingoCard;
