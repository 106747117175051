import React from 'react';
import './App.css';
import BingoCard from "./BingoCard";

function Reset() {
    function handleClick() {
        const confirm = window.confirm("This will reset everything! Are you sure?")
        if (confirm === true){
            localStorage.clear();
            window.location.reload();
        }

    }
    return (
        <button onClick={() => {handleClick()}}>Reset</button>
    );
}

function App() {
    return (
        <div className="App">
            <div className="title">
                <h3>When you get 5 in a row, say BINGO!</h3>
                <p>(Only horizontal and vertical lines count.)</p>
            </div>
            <BingoCard />
            <Reset />
        </div>
    );
}

export default App;
